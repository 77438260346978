import { HeroPersonalizationProps, UseHeroPersonalization } from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";
import { FC } from "react";
import ProductSearch from "@/components/ProductSearch";

const CUSTOM_RULE_ID = "heroPersonalizationTest";

const Component: FC<HeroPersonalizationProps> = ({
  title,
  description,
  eventing,
}) => {
  const parsedTitle = (
    title?.split(/(\*.*?\*)/g).filter((part) => part !== "") ?? []
  ).map((s) => {
    if (s.startsWith("*")) {
      return (
        <span key={s} className="text-accent-pink">
          {s.replaceAll("*", "")}
        </span>
      );
    }
    return s;
  });

  return (
    <>
      <h1>{parsedTitle}</h1>
      <p className="mb-12">{description}</p>
      <ProductSearch
        placeholder="Search our 4000+ courses"
        conversionType={eventing?.conversionType ?? "hero-search"}
      />
    </>
  );
};

const useHeroPersonalization: UseHeroPersonalization = () => {
  const { isLoading, value } = useMonarchRule<{
    data: HeroPersonalizationProps;
    variantCustomId: string;
  }>(CUSTOM_RULE_ID, {});

  if (isLoading) {
    return {
      isLoading,
      value: undefined,
    };
  }

  return {
    isLoading,
    value:
      value?.variantCustomId &&
      !value?.variantCustomId?.startsWith("homepageHeroControl") ? (
        <Component {...value.data} variantCustomId={value.variantCustomId} />
      ) : undefined,
  };
};

export default useHeroPersonalization;
